import React, { useState, useEffect } from 'react';

const RotatingTitle = () => {
  const words = ["Developer", "Designer", "Creator", "Geek"];
  const [index, setIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsAnimating(false);
      }, 500); // Duration of exit animation
    }, 2000); // Change word every 2 seconds
    return () => clearInterval(intervalId);
  }, [words.length]);

  return (
    <h3 id = "RotatingTitle" style={{ display: 'flex', alignItems: 'center' }}>
      I am a 
      <span style={{ display: 'inline-block', position: 'relative', marginLeft: '15px', height: '75px', color: '#0BB1F9'}}>
        <span
          key={index}
          className={`word ${isAnimating ? 'exit' : 'enter'}`}
          style={{ position: 'absolute', left: 0, whiteSpace: 'nowrap' }}
        >
          {words[index]}
        </span>
      </span>
      <style jsx>{`
        .enter {
          animation: slideDownIn 0.5s ease forwards;
        }

        .exit {
          animation: slideDownOut 0.5s ease forwards;
        }

        @keyframes slideDownIn {
          0% {
            transform: translateY(-100%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes slideDownOut {
          0% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(100%);
            opacity: 0;
          }
        }
      `}</style>
    </h3>
  );
};

export default RotatingTitle;

