import React, { useEffect, useState } from 'react';

function Header() {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.Hero, .About, .Projects, .Contact');
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        // Check if scroll position is within the section or near the separator
        if (scrollPosition >= sectionTop - 200 && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(section.className);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="App-header">
      <a href="#Hero" className={activeSection === 'Hero' ? 'active' : ''}>Home</a>
      <a href="#About" className={activeSection === 'About' ? 'active' : ''}>About</a>
      <a href="#ProjectsSep" className={activeSection === 'Projects' ? 'active' : ''}>Projects</a>
      <a href="#Contact" className={activeSection === 'Contact' ? 'active' : ''}>Contact</a>
    </header>
  );
}

export default Header;