import React, { useState, useEffect } from 'react';
import './App.css';
import './SkillCards.css';
import Header from './Header';
import Footer from './Footer';
import RotatingTitle from './RotatingTitle.js';
import myImage from './Imgs/HeroImage.png';
import Figma from './Imgs/Figma.svg';
import Wordpress from './Imgs/Wordpress.svg';
import ReactLogo from './Imgs/React.svg';
import UnrealEngine from './Imgs/UnrealEngine.svg';
import CPlusPlus from './Imgs/CPlusPlus.svg';
import Blender from './Imgs/Blender.svg';
import Javascript from './Imgs/Javascript.svg';
import CSS from './Imgs/CSS.svg';
import HTML from './Imgs/HTML.svg';
import CSharp from './Imgs/CSharp.svg';
import Python from './Imgs/Python.svg';
import Java from './Imgs/Java.svg';
import CaraTeriCollage from './Imgs/CaraTeriCollage.jpg';
import CaraTeriCollageLowQuality from './Imgs/CaraTeriCollageLowQuality.jpg';
import DressToImpress from './Imgs/DressToImpress.jpg';
import ImpressYourCat from './Imgs/ImpressYourCat.jpg';
import NoFishyBusiness from './Imgs/NoFishyBusiness.jpg';
import PutABowOnIt from './Imgs/PutABowOnIt.jpg';
import DressToImpressLowQuality from './Imgs/DressToImpressLowQuality.jpg';
import ImpressYourCatLowQuality from './Imgs/ImpressYourCatLowQuality.jpg';
import NoFishyBusinessLowQuality from './Imgs/NoFishyBusinessLowQuality.jpg';
import PutABowOnItLowQuality from './Imgs/PutABowOnItLowQuality.jpg';
import FullscreenImage from './FullscreenImage';

import PostersAnim from './Videos/CaraTeriPosterAnim.mp4';
import CardAnim from './Videos/CardAnim.webm';
import Canvas from './Canvas.js';
import SkillCards from './SkillCards.js';
import ContactForm from './ContactForm.js';
import WobbleButton from './WobbleButton.js';
import EyeTrackingImage from './EyeTrackingImage.js';



function App() {

  const [screenSize, setScreenSize] = useState('desktop');

  const updateScreenSize = () => {
    if (window.innerWidth < 481) {
      setScreenSize('mobile');
    } else if (window.innerWidth >= 481 && window.innerWidth < 769) {
      setScreenSize('tablet');
    } else {
      setScreenSize('desktop');
    }
  };

  useEffect(() => {
    updateScreenSize(); // Set initial screen size on mount
    window.addEventListener('resize', updateScreenSize); // Update on window resize
    return () => window.removeEventListener('resize', updateScreenSize); // Clean up the listener
  }, []);

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null); // To store the timeout ID

  const handleHover = (category) => {
    // Clear any existing timeout if hovering again
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setHoveredCategory(category);
  };

  const handleLeave = () => {
    // Set a timeout to clear hoveredCategory
    const timeoutId = setTimeout(() => {
      setHoveredCategory(null);
    }, 200); // Adjust the delay time as necessary

    setHoverTimeout(timeoutId); // Save the timeout ID
  };



  const skillCardsData = [
    { description: "UI/UX Design with Figma", imageUrl: Figma, category: 'Web Design' },
    { description: "Web Dev with Wordpress", imageUrl: Wordpress, category: 'Web Design' },
    { description: "Web/App Dev with React", imageUrl: ReactLogo, category: 'Web Design' },
    { description: "Game Dev with Unreal Engine", imageUrl: UnrealEngine, category: 'Game Dev' },
    { description: "Coding with C++", imageUrl: CPlusPlus, category: 'Coding' },
    { description: "3D Modeling with Blender", imageUrl: Blender, category: 'Game Dev' },
    { description: "Coding with Javascript", imageUrl: Javascript, category: 'Web Design' },
    { description: "Web Dev with CSS", imageUrl: CSS, category: 'Web Design' },
    { description: "Web Dev with HTML", imageUrl: HTML, category: 'Web Design' },
    { description: "Coding with C#", imageUrl: CSharp, category: 'Coding' },
    { description: "Coding with Python", imageUrl: Python, category: 'Coding' },
    { description: "Coding with Java", imageUrl: Java, category: 'Coding' },
    // Add other card data similarly
  ];

  // Define the specific cards you want to display
  const specificCards = [
    skillCardsData[0],
    skillCardsData[2],
    skillCardsData[6],
  ];

  return (

    <div id="Hero" className="App">
      <Header />
      <main>
        {/*<Canvas />*/}
        {screenSize === 'desktop' && (
          <section class="Hero">
            <div class = "HeroContainer">
              <div className="HeroTitle">
                <h2>
                  {["G", "I", "A", "N", "N", "I"].map((letter, index) => (
                    <span key={index} className="title-letter">{letter}</span>
                  ))}
                </h2>
                <h2>
                  {["C", "O", "N", "T", "R", "E", "R", "A", "S"].map((letter, index) => (
                    <span key={index + 6} className="title-letter">{letter}</span>
                  ))}
                </h2>
                <h3 id="aka">aka NiceFriendlyGuy</h3>
              </div>
              <br></br>
              <h3>UI/UX Design</h3>
              <h3>Software Development</h3>
              <h3>Game Development</h3>
              <div class="separator"></div>
              <h3>This website is made with</h3>
              <section className="FeaturedSkills">
                <div className="FeaturedSkillCards">
                  {specificCards.map((card, index) => (
                    <SkillCards
                      key={index}
                      description={card.description}
                      imageUrl={card.imageUrl}
                      category={card.category}
                      isActive={hoveredCategory === card.category}
                      isBlurred={hoveredCategory && hoveredCategory !== card.category} // Blur if not in the hovered category
                      onHover={handleHover}
                      onLeave={handleLeave}
                    // Pass additional props as needed
                    />
                  ))}
                </div>
              </section>
              <a id="SeeMore" href="#About">See more of my skills</a>
            </div>
            <div class="HeroImageContainer">
              <WobbleButton />
              <img src={myImage} height="auto" width="1000px" ></img>
            </div>
          </section>
        )}

        {/*MOBILE*/}
        {screenSize === 'mobile' && (
          <section class="Hero">
            <div class="HeroContainer">
              <div class="HeroImageContainer">
                <WobbleButton />
                <img src={myImage} height="auto" width="1000px" ></img>
              </div>
              <div className="HeroTitle">
                <h2>
                  {["G", "I", "A", "N", "N", "I"].map((letter, index) => (
                    <span key={index} className="title-letter">{letter}</span>
                  ))}
                </h2>
                <h2>
                  {["C", "O", "N", "T", "R", "E", "R", "A", "S"].map((letter, index) => (
                    <span key={index + 6} className="title-letter">{letter}</span>
                  ))}
                </h2>
                <h3 id="aka">aka NiceFriendlyGuy</h3>
                <br></br>
              </div>
              <div class="spacer"></div>
              <h3>UI/UX Design</h3>
              <h3>Software Development</h3>
              <h3>Game Development</h3>
              <div class="separator"></div>
              <h3 id="MadeWith">This website is made with</h3>
              <section className="FeaturedSkills">
                <div className="FeaturedSkillCards">
                  {specificCards.map((card, index) => (
                    <SkillCards
                      key={index}
                      description={card.description}
                      imageUrl={card.imageUrl}
                      category={card.category}
                      isActive={hoveredCategory === card.category}
                      isBlurred={hoveredCategory && hoveredCategory !== card.category} // Blur if not in the hovered category
                      onHover={handleHover}
                      onLeave={handleLeave}
                    // Pass additional props as needed
                    />
                  ))}
                </div>
              </section>
              <a href="#About">See more of my skills</a>
            </div>
          </section>
        )}
        {/*TABLET*/}
        {screenSize === 'tablet' && (
          <div>
            <section class="Hero">
              <div class = "HeroContainer">
                <div className="HeroTitle">
                  <h2>
                    {["G", "I", "A", "N", "N", "I"].map((letter, index) => (
                      <span key={index} className="title-letter">{letter}</span>
                    ))}
                  </h2>
                  <h2>
                    {["C", "O", "N", "T", "R", "E", "R", "A", "S"].map((letter, index) => (
                      <span key={index + 6} className="title-letter">{letter}</span>
                    ))}
                  </h2>
                  <h3 id="aka">aka NiceFriendlyGuy</h3>
                </div>
                <br></br>
                <h3>UI/UX Design</h3>
                <h3>Software Development</h3>
                <h3>Game Development</h3>
                <div class="separator"></div>
                <h3>This website is made with</h3>
                <section className="FeaturedSkills">
                  <div className="FeaturedSkillCards">
                    {specificCards.map((card, index) => (
                      <SkillCards
                        key={index}
                        description={card.description}
                        imageUrl={card.imageUrl}
                        category={card.category}
                        isActive={hoveredCategory === card.category}
                        isBlurred={hoveredCategory && hoveredCategory !== card.category} // Blur if not in the hovered category
                        onHover={handleHover}
                        onLeave={handleLeave}
                      // Pass additional props as needed
                      />
                    ))}
                  </div>
                </section>
                <a href="#About">See more of my skills</a>
              </div>
              <div class="HeroImageContainer">
                <WobbleButton />
                <img src={myImage} height="auto" width="1000px" ></img>
              </div>
            </section>
          </div>
        )}



        <div id="About" class="separation">
          <div class="separator"></div>
          <h2>About</h2>
          <div class="separator"></div>
        </div>

        <section class="About">
          <div class="AboutFirst">
            <div class="AboutTitle">
              <h3 id="Who1">Who am I ?</h3>
              <h3 id="Who2">Passoniate about everything computer related, I do it all !</h3>
            </div>
            <div class="AboutPicText">
              <div class="AboutImgContainer">
                <EyeTrackingImage />
              </div>
              {screenSize === 'desktop' && (
                <div class="VerticalSeparator"></div>
              )}
              <div class="AboutText">
                <RotatingTitle />
                <h3 id="Who">A little bit about me</h3>
                <p>
                  💻 <strong>Tech Enthusiast Since Day One</strong><br />
                  I've been passionate about computers and technology from a young age, diving into programming early on and building a strong foundation in object-oriented languages.<br /><br />
                  🌐 <strong>Web Mastering</strong><br />
                  These days, I’m channeling those skills into web design and development, crafting sleek, functional websites.<br /><br />
                  🎮 <strong>Jack of All Trades</strong><br />
                  My interests don’t stop there! I’m also passionate about software development, game design, 3D modeling, and all things tech-related.<br /><br />
                  ✨ <strong>Creative Meets Technical</strong><br />
                  I love combining creativity with technical expertise, focusing on detail to deliver user-friendly and engaging digital experiences.<br />
                </p></div>
            </div>
            <div class="Best">
              <h2>What I do best</h2>
              <div class="separator"></div>
              <div class="SkillCards">
                {skillCardsData.map((card, index) => (
                  <SkillCards
                    key={index}
                    description={card.description}
                    imageUrl={card.imageUrl}
                    category={card.category}
                    isActive={hoveredCategory === card.category}
                    isBlurred={hoveredCategory && hoveredCategory !== card.category} // Blur if not in the hovered category
                    onHover={handleHover}
                    onLeave={handleLeave}
                  />
                ))}
              </div>
            </div>
          </div>
          <div>

          </div>
        </section>

        <div class="separation" id="ProjectsSep">
          <div class="separator"></div>
          <h2>Projects</h2>
          <div class="separator"></div>
        </div>
        <section class="Projects">
          <div class="ProjectsTextCard">
            <div class="ProjectsFirst">
              <div>
                <div class="CaraTeriLink">
                  <a href="https://boutiquecarateri.ch/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <h2>Cara Teri</h2>
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M37.5001 18.75H21.8751C21.0463 18.75 20.2515 19.0792 19.6654 19.6653C19.0794 20.2513 18.7501 21.0462 18.7501 21.875V53.125C18.7501 53.9538 19.0794 54.7487 19.6654 55.3347C20.2515 55.9208 21.0463 56.25 21.8751 56.25H53.1251C53.9539 56.25 54.7488 55.9208 55.3348 55.3347C55.9209 54.7487 56.2501 53.9538 56.2501 53.125V37.5M37.5001 37.5L60.9376 14.0625M46.8751 9.375H65.6251V28.125" stroke="black" stroke-width="7.35243" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </div>
                <h3>Woman's retro fashion shop</h3>
              </div>
              {screenSize === 'mobile' && (
                <div class="spacer"></div>
              )}
              {screenSize === 'tablet' && (
                <div class="spacer"></div>
              )}
              <h3 class="YellowText">- Web Design<br></br>- Web Development<br></br>- Posters Design<br></br>- Business Cards Design</h3>
              {screenSize === 'desktop' && (
                <div class="spacer"></div>
              )}
            </div>
            <video class="BusinessCard" muted autoPlay loop width={750} >
              <source src={CardAnim} type="video/webm"></source>
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="Posters">
            <FullscreenImage
              thumbnailSrc={CaraTeriCollageLowQuality} // Low-quality thumbnail
              fullImageSrc={CaraTeriCollage}           // High-quality full image
              alt="CaraTeriCollage Poster"
            />
            <FullscreenImage
              thumbnailSrc={DressToImpressLowQuality} // Low-quality thumbnail
              fullImageSrc={DressToImpress}           // High-quality full image
              alt="DressToImpress Poster"
            />
            <FullscreenImage
              thumbnailSrc={ImpressYourCatLowQuality} // Low-quality thumbnail
              fullImageSrc={ImpressYourCat}           // High-quality full image
              alt="ImpressYourCat Poster"
            />
            <FullscreenImage
              thumbnailSrc={NoFishyBusinessLowQuality} // Low-quality thumbnail
              fullImageSrc={NoFishyBusiness}           // High-quality full image
              alt="NoFishyBusiness Poster"
            />
            <FullscreenImage
              thumbnailSrc={PutABowOnItLowQuality} // Low-quality thumbnail
              fullImageSrc={PutABowOnIt}           // High-quality full image
              alt="PutABowOnIt Poster"
            />
          </div>
          <video class="PostersAnim" muted autoPlay loop width={500}>
              <source src={PostersAnim} type="video/mp4"></source>
              Your browser does not support the video tag.
            </video>
        </section>

        <div id="Contact" class="separation">
          <div class="separator"></div>
          <h2>Contact</h2>
          <div class="separator"></div>
        </div>
        <section class="Contact">
          <div class="ContactTitle">
            <h2>In need of some design in your world ?</h2>
            <h3>Here's where you can find me</h3>
          </div>
          <div class="ContactContainer">
            <div class="FirstContact">
              <div class="SvgSocials">
                <button>
                <a
                  href="https://www.instagram.com/contrerasgianni/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    viewBox="0 0 119 120"
                    fill="#61dafb"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ background: 'none' }}
                  >
                    <circle
                      cx="59.4647"
                      cy="59.5951"
                      r="58.4647"
                      stroke="none"
                      stroke-width="2"
                      class="icon-circle"
                    />
                    <path
                      d="M45.2615 24.1577H75.3242C86.7767 24.1577 96.0818 33.4628 96.0818 44.9153V74.978C96.0818 80.4833 93.8948 85.763 90.002 89.6558C86.1092 93.5486 80.8295 95.7356 75.3242 95.7356H45.2615C33.809 95.7356 24.5039 86.4305 24.5039 74.978V44.9153C24.5039 39.41 26.6909 34.1303 30.5837 30.2375C34.4765 26.3447 39.7562 24.1577 45.2615 24.1577ZM44.5457 31.3155C41.1287 31.3155 37.8516 32.6729 35.4353 35.0891C33.0191 37.5054 31.6617 40.7825 31.6617 44.1995V75.6938C31.6617 82.8158 37.4237 88.5778 44.5457 88.5778H76.04C79.457 88.5778 82.7341 87.2204 85.1504 84.8042C87.5666 82.3879 88.924 79.1108 88.924 75.6938V44.1995C88.924 37.0775 83.162 31.3155 76.04 31.3155H44.5457ZM79.082 36.6838C80.2685 36.6838 81.4064 37.1552 82.2454 37.9941C83.0843 38.8331 83.5557 39.971 83.5557 41.1575C83.5557 42.3439 83.0843 43.4818 82.2454 44.3208C81.4064 45.1598 80.2685 45.6311 79.082 45.6311C77.8956 45.6311 76.7577 45.1598 75.9187 44.3208C75.0797 43.4818 74.6084 42.3439 74.6084 41.1575C74.6084 39.971 75.0797 38.8331 75.9187 37.9941C76.7577 37.1552 77.8956 36.6838 79.082 36.6838ZM60.2928 42.0522C65.0388 42.0522 69.5903 43.9375 72.9461 47.2934C76.302 50.6492 78.1873 55.2007 78.1873 59.9467C78.1873 64.6926 76.302 69.2441 72.9461 72.6C69.5903 75.9558 65.0388 77.8411 60.2928 77.8411C55.5469 77.8411 50.9954 75.9558 47.6395 72.6C44.2837 69.2441 42.3984 64.6926 42.3984 59.9467C42.3984 55.2007 44.2837 50.6492 47.6395 47.2934C50.9954 43.9375 55.5469 42.0522 60.2928 42.0522ZM60.2928 49.21C57.4453 49.21 54.7144 50.3412 52.7009 52.3547C50.6873 54.3682 49.5562 57.0991 49.5562 59.9467C49.5562 62.7942 50.6873 65.5251 52.7009 67.5386C54.7144 69.5522 57.4453 70.6833 60.2928 70.6833C63.1404 70.6833 65.8713 69.5522 67.8848 67.5386C69.8983 65.5251 71.0295 62.7942 71.0295 59.9467C71.0295 57.0991 69.8983 54.3682 67.8848 52.3547C65.8713 50.3412 63.1404 49.21 60.2928 49.21Z"
                      fill="#000000"
                      class="icon-path"
                    />
                  </svg>
                  </a>
                </button>
                <h3>@ContrerasGianni</h3>
              </div>
              <div class="SvgSocials">
                <button>
                <a
                  href="https://www.linkedin.com/in/gianni-contreras-0aa58a24a/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    viewBox="0 0 119 119"
                    fill="#61dafb"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ background: 'none' }}
                  >
                    <circle
                      cx="59.4647"
                      cy="59.4647"
                      r="58.4647"
                      stroke="#FFF600"
                      stroke-width="2"
                      class="icon-circle"
                    />
                    <path
                      d="M84.8262 25.7251C86.7793 25.7251 88.6525 26.501 90.0335 27.882C91.4146 29.2631 92.1905 31.1362 92.1905 33.0894V84.6392C92.1905 86.5923 91.4146 88.4655 90.0335 89.8465C88.6525 91.2276 86.7793 92.0035 84.8262 92.0035H33.2764C31.3232 92.0035 29.4501 91.2276 28.0691 89.8465C26.688 88.4655 25.9121 86.5923 25.9121 84.6392V33.0894C25.9121 31.1362 26.688 29.2631 28.0691 27.882C29.4501 26.501 31.3232 25.7251 33.2764 25.7251H84.8262ZM82.9851 82.7981V63.2828C82.9851 60.0992 81.7205 57.046 79.4693 54.7949C77.2182 52.5438 74.165 51.2791 70.9814 51.2791C67.8516 51.2791 64.2063 53.1938 62.4389 56.0659V51.9787H52.1657V82.7981H62.4389V64.6452C62.4389 61.81 64.7218 59.4902 67.557 59.4902C68.9242 59.4902 70.2354 60.0334 71.2021 61.0001C72.1689 61.9669 72.712 63.278 72.712 64.6452V82.7981H82.9851ZM40.1988 46.1978C41.8394 46.1978 43.4128 45.546 44.5729 44.3859C45.733 43.2258 46.3848 41.6524 46.3848 40.0118C46.3848 36.5874 43.6232 33.789 40.1988 33.789C38.5484 33.789 36.9656 34.4446 35.7986 35.6116C34.6316 36.7786 33.976 38.3614 33.976 40.0118C33.976 43.4362 36.7744 46.1978 40.1988 46.1978ZM45.3169 82.7981V51.9787H35.1174V82.7981H45.3169Z"
                      fill="#000000"
                      class="icon-path"
                    />
                  </svg>
                  </a>
                </button>
                <h3>Gianni Contreras</h3>
              </div>
              <div class="SvgSocials">
                <button>
                <a
                  href="#Contact"
                >
                  <svg
                    viewBox="0 0 102 102"
                    fill="#61dafb"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ background: 'none' }}
                  >
                    <circle
                      cx="51"
                      cy="51"
                      r="49"
                      stroke="#FFF600"
                      strokeWidth="2"
                      fill="#61dafb"
                      className="icon-circle"
                    />
                    <path
                      d="M28.2454 72.1824C26.735 72.1824 25.4425 71.645 24.3679 70.5704C23.2933 69.4958 22.755 68.2024 22.7532 66.6902V33.7373C22.7532 32.2269 23.2914 30.9344 24.3679 29.8598C25.4444 28.7852 26.7369 28.2469 28.2454 28.2451H72.1826C73.6929 28.2451 74.9863 28.7833 76.0628 29.8598C77.1393 30.9363 77.6766 32.2288 77.6748 33.7373V66.6902C77.6748 68.2005 77.1374 69.4939 76.0628 70.5704C74.9882 71.6469 73.6948 72.1842 72.1826 72.1824H28.2454ZM50.214 52.9598L72.1826 39.2294V33.7373L50.214 47.4677L28.2454 33.7373V39.2294L50.214 52.9598Z"
                      fill="#000000"
                      className="icon-path"
                    />
                  </svg>
                  </a>
                </button>
                {screenSize === 'desktop' && (
                  <h3>Gianni.Contreras96@hotmail.fr</h3>
                )}
                {screenSize === 'tablet' && (
                  <h3>Gianni.Contreras96@hotmail.fr</h3>
                )}
                {screenSize === 'mobile' && (
                  <h3>Gianni.Contreras96<br></br>@hotmail.fr</h3>
                )}
              </div>
            </div>
            {screenSize === 'desktop' && (
              <div class="VerticalSeparator"></div>
            )}
            {screenSize === 'mobile' && (
              <div class="separator"></div>
            )}
            <div>
              <ContactForm />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
