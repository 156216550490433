import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.name && formData.email && formData.message) {
      try {
        const response = await fetch('https://giannicontreras.ch/send_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(formData).toString(),
        });

        const result = await response.json();
        if (response.ok) {
          setSubmitted(true);
          setError('');
          setFormData({ name: '', email: '', message: '' });
        } else {
          setError(result.message || 'Failed to send your message.');
        }
      } catch (error) {
        setError('An error occurred while sending your message.');
      }
    } else {
      setError('Please fill out all fields.');
    }
  };

  return (
    <div className="contact-form-container">
      <h3>Get in touch</h3>
      {submitted && <p>Thank you for contacting us!</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          required
        ></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactForm;
