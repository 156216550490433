import React, { useState } from 'react';

const FullscreenImage = ({ thumbnailSrc, fullImageSrc, alt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {/* Low-quality thumbnail */}
      <img
        src={thumbnailSrc}
        alt={alt}
        onClick={openModal}
        style={{
          cursor: 'pointer',
          display: 'block',
          margin: '0 auto',
        }}
      />

      {/* High-quality fullscreen image */}
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
          onClick={closeModal} // Close modal when clicking outside the image
        >
          <img
            src={fullImageSrc}
            alt={alt}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%',
              boxShadow: '0 0 15px rgba(255, 255, 255, 0.5)',
              borderRadius: '8px',
            }}
          />
        </div>
      )}
    </>
  );
};

export default FullscreenImage;
