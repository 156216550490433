import React, { useState, useEffect } from 'react';

const SkillCard = ({ description, imageUrl, category, isActive, isBlurred, onHover, onLeave }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={`card ${isActive ? 'hovered' : ''} ${isBlurred ? 'blurred' : ''}`}
      onMouseEnter={isDesktop ? () => onHover(category) : null}
      onMouseLeave={isDesktop ? onLeave : null}
    >
      <img src={imageUrl} alt="Skill" />
      <h4>{description}</h4>
    </div>
  );
};

export default SkillCard;
